<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div
      class="btnList1"
      ref="btnList"
    >
      <el-button
        v-if="hasPermiss('bjgl_keHuBaoJia:add')"
        class="itemBtn btnColor"
        @click="addItem"
      >新建</el-button>
      <el-button
        v-if="hasPermiss('bjgl_keHuBaoJia:update')"
        class="itemBtn btnColor"
        @click="modifyItem"
      >修改</el-button>
      <el-button
        v-if="hasPermiss('bjgl_keHuBaoJia:del')"
        class="itemBtn btnColor"
        @click="delItem"
      >删除</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="Enable"
      >启用</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="Disable"
      >禁用</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="myModel"
      >列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">所属渠道:</div>
            <div class="singleinpright">
              <el-select
                class="inputframe"
                v-model="queryData.channelId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in channelArr"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">渠道报价名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.offerName"
                placeholder="请输入"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div
            class="singleinpbtn"
            v-if="hasPermiss('bjgl_keHuBaoJia:query')"
          >
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
            >查询</el-button>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        show-summary
        :summary-method="getSummaries"
        :header-cell-style="{ background: '#f0f0f0' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        >
        </el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        >
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>

    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>

    <!-- 上传编辑好的导出模板-->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            >上传模板</div>
          </el-upload>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 新建 -->
    <!-- <el-dialog :title="subTitle" :visible.sync="show_cate">
          <div class="formbox">
            <el-form
              ref="formCate"
              label-position="left"
              :model="infosForm"
              :rules="infoRules"
              label-width="100px"
            >
              <el-form-item label="报价名称" prop="offerName">
                <el-input v-model="infosForm.offerName"></el-input>
              </el-form-item>
              <el-form-item label="所属渠道" prop="offerName">
                <el-select
                  class="inputframe"
                  v-model="infosForm.channelId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in channelArr"
                    :key="item.channelId"
                    :label="item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="有效时间" prop="effectiveTime">
                <el-date-picker
                  v-model="infosForm.effectiveTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd hh:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="headebut">
            <el-button @click="submitInfo('formCate')" type="warning"
              >保存</el-button
            >
          </div>
        </el-dialog> -->
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import draggable from 'vuedraggable' //拖拽组件
import paging from '@/components/pagings.vue'
import sorttable from '@/components/sortTemplate.vue'

export default {
  inject: ['reload'],
  components: {
    // mytables,
    draggable,
    paging,
    sorttable,
  },
  data() {
    return {
      queryData: {
        channelId: '',
        offerName: '',
      },
      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: '50vh',
      tabelHeadeTitle: [
        {
          name: '渠道报价名',
          field: 'offerName',
          sort: 1,
          isShow: true,
          isTotal: true,
          width: 120,
        },
        {
          name: '是否根据会员等级匹配',
          field: 'isCheckMemberlevelShow',
          sort: 1,
          isShow: true,
          isTotal: true,
          width: 120,
        },
        {
          name: '适用会员等级',
          field: 'confMemberlevelName',
          sort: 1,
          isShow: true,
          isTotal: true,
          width: 120,
        },
        {
          name: '发出仓库',
          field: 'startStorageName',
          sort: 6,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: '到达仓库',
          field: 'endStorageName',
          sort: 6,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: '所属渠道',
          field: 'channelName',
          sort: 2,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: '状态',
          field: 'isEnableShow',
          sort: 2,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: '备注',
          field: 'comment',
          sort: 2,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: '有效开始时间',
          field: 'effectiveStartTime',
          sort: 3,
          isShow: true,
          isTotal: false,
          width: 160,
        },
        {
          name: '有效结束时间',
          field: 'effectiveEndTime',
          sort: 4,
          isShow: true,
          isTotal: false,
          width: 160,
        },
        {
          name: '创建时间',
          field: 'createTime',
          sort: 5,
          isShow: true,
          isTotal: false,
          width: 160,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: '', //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: '', //上传路径
      sortName: '模板名', //排序名
      pageGroupName: 'CustomerQuotation1', //页面标识   (注意这里,每个列表定义一个唯一的标识,自定义,设置表头或者下载需要用到)
      currentItemArr: [],
      channelArr: [], // 所有渠道
      show_cate: false,
      subTitle: '新建渠道报价',
      infosForm: {
        channelId: '',
        offerName: '',
        effectiveTime: '',
      },
      cateStatusArr: [],
      infoRules: {
        offerName: [
          { required: true, message: '请输入渠道报价名称', trigger: 'blur' },
        ],
        channelId: [
          { required: true, message: '请选择所属渠道', trigger: 'blur' },
        ],
        effectiveTime: [
          { required: true, message: '请选择有效时间', trigger: 'blur' },
        ],
      },
      originalTabelHeadeTitle: [],
      //===============
    }
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1
      }
      this.tabelHeadeTitle = arr
    }
  },
  created() {
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      )
    }
    this.uploadUrl = this.common.uploadUrl
    this.myTableHeard = this.tabelHeadeTitle
    this.getGroupFindFieldSort()

    this.getAllChannel()
  },
  mounted() {
    const that = this
    this.getData()
  },
  watch: {},
  methods: {
    //禁用==0
    Disable() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning('请至少勾选一条数据')
      } else {
        this.$confirm('确定要禁用当前报价吗?', '提示:')
          .then(() => {
            this.channelOfferIsEnable(0)
          })
          .catch(() => {})
      }
    },
    //启用==1
    Enable() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning('请至少勾选一条数据')
      } else {
        this.channelOfferIsEnable(1)
      }
    },
    //启用禁用
    channelOfferIsEnable(isEnable) {
      var channelAgentId = []
      var parm = new Object()
      for (var i = 0; i < this.currentItemArr.length; i++) {
        if (this.currentItemArr[i].offerId) {
          channelAgentId.push(Number(this.currentItemArr[i].offerId))
        }
      }
      parm.channelOfferId = channelAgentId
      parm.isEnable = isEnable
      let sign = tools.getSign(parm)
      parm.sign = sign
      console.log(parm)
      //console.log(channelAgentId)
      //console.log(isEnable)
      Api.channelOfferIsEnable(parm).then((res) => {
        if (res.data.status == 'success') {
          this.$message.success(res.data.message || '操作成功')
          this.getData()
        }
      })
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      }
    },
    getAllChannel() {
      Api.getChannelList({
        channelName: '',
        pageStart: 1,
        pageTotal: 999,
      }).then((res) => {
        if (res.data.status == 'success') {
          this.channelArr = res.data.result.data || []
        }
      })
    },
    addItem() {
      this.$router.push({
        path: '/PayableManagement/NewQuotation',
        query: {
          pageType: 'add',
        },
      })
      // this.show_cate = true;
      // this.subTitle = "新建渠道报价";
      // this.infosForm = {
      //   channelId: "",
      //   effectiveTime: "",
      //   offerName: "",
      // };
      // if (this.$refs.formCate) {
      //   this.$refs.formCate.resetFields();
      // }
    },
    //修改
    modifyItem() {
      if (this.currentItemArr.length == 1) {
        // this.show_cate = true;
        // this.subTitle = "修改渠道报价";
        const curItem = this.currentItemArr[0]
        this.$router.push({
          path: '/PayableManagement/NewQuotation',
          query: {
            data: JSON.stringify(curItem),
            pageType: 'edit',
          },
        })
      } else if (this.currentItemArr.length > 1) {
        this.$message.warning('一次只能修改一个报价~')
        return
      } else {
        this.$message.warning('请选择一个需要修改的渠道报价')
        return
      }
    },
    delItem() {
      if (this.currentItemArr.length == 1) {
        this.$confirm('是否删除选择的渠道报价?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let signParam = { offerId: this.currentItemArr[0]['offerId'] }
            const sign = tools.getSign(signParam)
            signParam.sign = sign
            Api.delChannelOffer(signParam).then((res) => {
              if (res.data.status == 'success') {
                this.$message.success('删除成功!')
                this.reload_s()
              } else {
                this.$message.error('删除失败!')
              }
            })
          })
          .catch(() => {
            this.$message.info('已取消')
          })
      } else {
        this.$message.warning('请选择需要删除的一个数据!')
      }
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == 'dc') {
        // 导出
        this.exportBtn()
      } else if (command == 'dcmb') {
        // 下载模板
        this.exportTemplateDownload()
      } else if (command == 'scmb') {
        // 上传模板
        this.upLoadBtn()
      }
    },
    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name

      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('fileName', fileName)
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == 'success') {
            let fileName = res.data.result.fileName

            let signArr = [
              {
                key: 'groupName',
                val: this.pageGroupName,
              },
              {
                key: 'templateName',
                val: this.sortName,
              },
              {
                key: 'fileName',
                val: fileName,
              },
            ]
            let sign_ = tools.getMD5Sign(signArr)
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || '更新成功')
              this.show_upload = false
            })
          }
        })
        .catch((err) => {})
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      let that = this
      if (head.length == 0) {
        let fieldNames = []
        let heads = []
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field)
            heads.push(this.myTableHeard[i].name)
          }
        }
        fieldName = fieldNames
        head = heads
      }
      var fieldName = this.fieldName
      var head = this.head
      if (head.length == 0) {
        let fieldNames = []
        let heads = []
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field)
            heads.push(this.myTableHeard[i].name)
          }
        }
        fieldName = fieldNames
        head = heads
      }

      //加密数组格式
      let signArr = [
        {
          key: 'fieldName',
          val: fieldName,
        },
        {
          key: 'head',
          val: head,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      }
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || ''
        //下载(这里其实是直接访问)
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this
      let datas = that.tableData
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      }
      let signarr = tools.getSignArr(parm)
      let signs_e = tools.getMD5Sign(signarr)
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true
    },
    // 点击导出按钮
    exportBtn() {
      let that = this
      let fieldName = []
      let head = []
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field)
          head.push(this.myTableHeard[i].name)
        }
      }
      this.fieldName = fieldName
      this.head = head

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId
          that.templateDataExport(customizedExportId)
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            '渠道报价列表'
          )
        }
      })
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0
        resolve()
      })
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight()
        // //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout()
        })
      })
    },
    // 获取表格高度
    getHeight() {
      let that = this
      let windonHeight = document.body.clientHeight
      let queryHeigth = 50
      if (that.judge == true) {
        queryHeigth = that.$refs.queryCriteria.offsetHeight //查询框
      } else {
        queryHeigth = 50
      }
      let btnListHeight = that.$refs.btnList.offsetHeight || 40 // 按钮列表
      let pagsHeigth = 40 //分页组件
      let heardHeight = 60 //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight
      return Math.floor(windonHeight - otherHeight - 10) || '50vh' //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload() //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData
      formData.pageStart = this.pageNum
      formData.pageTotal = this.size
      this.currentItemArr = []

      Api.getChannelOfferList(formData).then((res) => {
        if (res.data.status == 'success') {
          this.className = res.data.result.className || ''
          this.tableData = res.data.result.data || []
          this.total = res.data.result.pageCount || 0
          this.fetTableHeight()
        }
      })
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1
      this.getData()
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        channelId: '',
        offerName: '',
      }
      this.queryBtn_ok()
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || []
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject)
            this.sortName = arr[0].sortName
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle
        }
        let totalArr = []
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field)
          }
        }
        that.totalArr = totalArr
      })

      that.$nextTick(() => {
        that.$refs.mytable.doLayout()
      })
    },
    // 设置模板按钮
    myModel() {
      let that = this
      that.show_sortTableHeard = true
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard))
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.currentItemArr = e
      } else {
        this.currentItemArr = []
      }
    },
    // =========================自定义排序弹窗方法=========================
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e
      this.myTableHeard = this.tabelHeadeTitle
      let sortObject = this.myTableHeard
      let signs = [
        {
          key: 'sortName',
          val: this.sortName, //模板名称
        },
        {
          key: 'groupName',
          val: this.pageGroupName, //页面标识
        },
        {
          key: 'sortObject',
          val: JSON.stringify(sortObject),
        },
      ]

      let sign = tools.getMD5Sign(signs)

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      }
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || '操作成功')
        this.getGroupFindFieldSort()
      })
      this.show_sortTableHeard = false //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      this.show_sortTableHeard = false
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this
      that.tabelHeadeTitle[idx].isShow = e
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    //全选
    checkAll(e) {
      let that = this
      this.allCheck = e
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e
      }
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e
    },
    // =========================自定义排序弹窗方法=========================
    // 合计
    getSummaries(param) {
      let that = this
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ''
            } else {
              sums[index] = '---'
            }
          }
        }
      })
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      return sums
    },

    //分页
    handleSizeChange(val) {
      this.size = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    submitInfo(formName) {
      // 新增和修改共用一个接口;
      this.$refs[formName].validate((valid) => {
        ////console.log();
        if (valid) {
          let signParam = {
            channelId: this.infosForm.channelId,
            effectiveStartTime: this.infosForm.effectiveTime[0],
            effectiveEndTime: this.infosForm.effectiveTime[1],
            offerName: this.infosForm.offerName,
            offerId: '',
          }
          if (this.subTitle == '修改渠道报价')
            signParam.offerId = this.currentItemArr[0]['offerId']
          let sign = tools.getSign(signParam)
          signParam.sign = sign
          Api.addOrUpdateChannelOffer(signParam).then((res) => {
            if (res.data.status == 'success') {
              this.show_cate = false
              this.$message.success(res.data.message || '操作成功')
              this.reload_s()
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.formbox ::v-deep {
  width: 80%;
  margin: 0 auto;
}

.headebut {
  width: 80%;
  margin: 0 auto;
  padding-left: 100px;
  box-sizing: border-box;
}

.el-range-separator ::v-deep {
  line-height: 22px;
}

.el-date-editor::v-deep {
  .el-range__icon {
    line-height: 25px;
  }
}
</style>
